import React from 'react';

import { dateHourFormatter, addressFormatter } from '../../../../utils';
import GenericEntry from '../../../../components/GenericSection/components/GenericEntry';
import { IDeliveryGroupDetails } from '../../../../interfaces/IOrder';
import { StyledLink, StyledList } from './styles';

const getTrackingUrl = (
  trackingNumber: string | undefined,
  postcode: string
): string =>
  trackingNumber
    ? ` https://track.dpd.co.uk/search?reference=${trackingNumber}&postcode=${postcode.replace(
        /\s/g,
        ''
      )}
`
    : '';

const DeliveryDetails: React.FC<{
  details: IDeliveryGroupDetails;
  consignmentNumber?: string;
  consignmentNumbers?: Array<string>;
  pickNumber?: string;
  inboundSlid?: string;
  outboundSlid?: string;
  redirectPick: CallableFunction;
  returnReferenceCode?: string;
  isInbound?: boolean;
  isTradeIn?: boolean;
}> = ({
  details,
  consignmentNumber,
  consignmentNumbers,
  pickNumber,
  inboundSlid,
  outboundSlid,
  redirectPick,
  returnReferenceCode,
  isInbound,
  isTradeIn
}) => (
  <>
    <h5 className='inner-accordion__details__subtitle '>Delivery details</h5>
    <div className='row'>
      <div className='column--w-50'>
        <GenericEntry code='Contact' value={details.contact} />
        <GenericEntry code='Company name' value={details.companyName} />
        <GenericEntry
          code='Address'
          value={addressFormatter(details.address)}
        />
        <GenericEntry code='Email' value={details.customerEmail} />
        <GenericEntry code='Contact number' value={details.contactNumber} />

        {consignmentNumbers && consignmentNumbers.length > 0 ? (
          <GenericEntry
            code='Consignment number(s)'
            value=''
            component={
              <StyledList>
                {consignmentNumbers.map((number: string, idx: number) => (
                  <React.Fragment key={idx}>
                    <GenericEntry
                      code=''
                      value={number}
                      noColon
                      link={getTrackingUrl(number, details.address.postCode)}
                    />
                    {idx !== consignmentNumbers.length - 1 && <pre>, </pre>}
                  </React.Fragment>
                ))}
              </StyledList>
            }
          />
        ) : (
          <GenericEntry
            code='Consignment number(s)'
            value={consignmentNumber || '-'}
            link={getTrackingUrl(consignmentNumber, details.address.postCode)}
          />
        )}
        {pickNumber && (
          <StyledLink
            type='button'
            aria-label='Search orders by pick number'
            onClick={() => redirectPick()}
          >
            <GenericEntry code='Pick number' value={pickNumber || '-'} />
          </StyledLink>
        )}
        {returnReferenceCode && (isInbound || isTradeIn) && (
          <GenericEntry
            code='Return reference code'
            value={returnReferenceCode || '-'}
          />
        )}
      </div>

      <div className='column--w-50'>
        <GenericEntry
          code='Method'
          value={`${details.dispatchMethod} ${
            details.dispatchMethodName ? `(${details.dispatchMethodName})` : ''
          }`}
          entryTag={details.dispatchMethodOverride ? '(Override) ' : ''}
        />
        <GenericEntry
          code='Service'
          value={`${details.service} ${
            details.serviceName ? `(${details.serviceName})` : ''
          }`}
          entryTag={details.serviceOverride ? '(Override) ' : ''}
        />
        <GenericEntry
          code='Requested delivery date'
          value={dateHourFormatter(details.scheduledDeliveryDate)}
        />
        <GenericEntry
          code='Predicted delivery date'
          value={dateHourFormatter(details.predictedDeliveryDate)}
        />
        <GenericEntry code='Inbound SLID' value={inboundSlid || '-'} />
        <GenericEntry code='Outbound SLID' value={outboundSlid || '-'} />
      </div>
    </div>
  </>
);

DeliveryDetails.defaultProps = {
  consignmentNumber: '',
  consignmentNumbers: [],
  pickNumber: '',
  inboundSlid: '',
  outboundSlid: '',
  returnReferenceCode: '',
  isInbound: false,
  isTradeIn: false
};

export default DeliveryDetails;
